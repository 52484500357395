<template>
  <ul class="sliding">
    <!-- Dynamic render element -->
    <li
      class="sliding__item"
      v-for="category in categories"
      :key="category.category_id"
      @change="setFilter(category.category_id)"
    >
      <input
        type="checkbox"
        class="sliding__title"
        :id="category.category_id"
        checked
      />
      <!-- 1 add filter by Beauty for others products -->
      <label :for="category.category_id" class="sliding__title">{{
        category.name
      }}</label>
      <ul class="sliding__content">
        <RadioButton
          v-for="i in category.categories"
          :key="i.id"
          :elements="i"
        />
      </ul>
    </li>
    <!-- End -->
  </ul>
</template>

<script>
import RadioButton from "@/components/shop/RadioButton.vue";

export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  components: {
    RadioButton,
  },
  methods: {
    setFilter(id) {
      if (id) {
        this.$router.push({
          name: "Catalog",
          query: {
            category: id,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
/* Sidebar menu */
.sliding .sliding__item > input {
  display: none;
}

.sliding li > ul.sliding__content {
  display: block;
  padding: 10px;
  border-radius: 5px;
  max-height: 0;
  opacity: 0;
  transition: all 0.2s linear;
}

.sliding li input.sliding__title:checked ~ ul.sliding__content {
  max-height: 1000px;
  opacity: 1;
  transition: all 0.2s linear;
}

.sliding .sliding__item > label {
  position: relative;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #75b051;
  padding: 35px 0;
  cursor: pointer;
}
.sliding .sliding__item input.sliding__title:checked ~ label.sliding__title::after {
  background: url("../../assets/img/sliding_icon.png") center/cover no-repeat;
  transform: rotate(180deg);
}
.sliding .sliding__item > label.sliding__title:after {
  position: absolute;
  content: "";
  background: url("../../assets/img/sliding_icon.png") center/cover no-repeat;
  width: 12px;
  height: 7px;
  top: 40px;
  left: 150px;
}

@media (max-width: 930px) {
  ul {
    padding-left: 0;
  }
}

@media (max-width: 768px) {
  .sliding {
    display: flex;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
    /* box-shadow: -5px 10px 25px rgba(117 176 81 / 25%); */
    margin: 15px 0 0 15px;
    padding: 10px;
    /* For burger */
    flex-direction: column;
    /* column-gap: 30px; */
  }
  .sliding__item {
    display: block;
    margin-top: 20px;
  }
  .sliding .sliding__item > label {
    padding: 0;
  }
  .sliding .sliding__item > label:after {
    display: none;
  }
  .sliding li > ul.sliding__content {
    max-height: 100vh;
    opacity: 1;
  }
}
</style>
