export default {
  computed: {
    outOfStock() {
      return this.product.stock_status == "В наличии" ? false : true;
    },
  },
  methods: {
    addToCart() {
      if (this.outOfStock && this.product.stock_status == "Нет в наличии") {
        return alert("Извините, но данного товара нет в наличии!");
      }
      this.$root.$emit("addedToCart", this.product);
    },
  }
}