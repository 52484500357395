<template>
  <section class="catalog">
    <div class="catalog__banner" @click="productDescription">
      <!-- <img :src="banner" alt="" /> -->
      <Carousel banners :products="banners" />
    </div>
    <div class="container">
      <ul class="breadcrumb">
        <li>
          <router-link to="/">Главная /</router-link>
        </li>
        <li>
          <router-link to="/products">Продукция</router-link>
        </li>
      </ul>
      <div class="catalog__body">
        <input id="sidebar-filter" type="checkbox" ref="sidebar" />
        <label for="sidebar-filter"><i class="fa fa-bars"></i></label>
        <div class="catalog__lists" @click="listener">
          <SidebarFilter :categories="categories" :brands="brands"/>
        </div>

        <div class="catalog__card">
          <Product-item
            v-for="product in products"
            :key="product.id"
            :product="product"
          />
        </div>
      </div>
      <div class="catalog__pages" id="targetScroll" v-if="!filter" :key="filter">
        <!-- <button @click="getProductsData()">ПОКАЗАТЬ ЕЩЕ</button> -->
      </div>
    </div>
  </section>
</template>

<script>
import ProductItem from "@/components/shop/ProductItem.vue";
import SidebarFilter from "@/components/shop/SidebarFilter.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    SidebarFilter,
    ProductItem,
    Carousel: () => import("@/components/ui/Carousel.vue"),
  },
  data() {
    return {
      banner: "",
      banners: [],
      limit: 6,
      filter: false,
      maxLength: null,
      categories: [],
      brands: []
    };
  },
  computed: {
    ...mapGetters("products", ["products"]),

    // showButton() {
    //   if (this.products.length >= this.maxLength || this.filter) {
    //     return false;
    //   }
    //   return true;
    // },
  },
  methods: {
    ...mapActions("products", ["getProducts"]),

    productDescription() {
      this.$router.push({ path: "/products" });
    },
    listener(e) {
      if (e.target.closest("li")) {
        this.$refs.sidebar.checked = false;
      }
    },

    async getProductsData() {
      // console.log(this.page)
      await this.getProducts({ page: 1, limit: this.limit + 6 });
      // this.page += 1;
      this.limit += 6;
      // await this.productsData.push(...prod);
    },

    async getBanner() {
      const banner = await this.$axios(
        "https://back.rizaherb.kz/index.php?route=feed/rest_api/banners&id=6"
      );
      this.banners = banner.data.data;
      // const { image } = banner.data.data[0];
      // this.banner = image;
    },

    async getTotalsId() {
      const allData = await this.$axios(
        "https://back.rizaherb.kz/index.php?route=feed/rest_api/products&simple=custom_fields=id"
      );

      const {
        data: {
          data: { length },
        },
      } = allData;
      this.maxLength = await length;
    },

    async getCategories() {
      const responseCategory = await this.$axios(
        "https://back.rizaherb.kz/index.php?route=feed/rest_api/categories&level=2"
      );
      const {
        data: { data },
      } = responseCategory;
      this.categories = data;
      // console.log(data);
    },

    viewObserver() {
      const page = document.querySelector("#targetScroll");
      if (this.filter) {
        return
      }
      let observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.getProducts({ page: 1, limit: this.limit + 6 });
              this.limit += 6;
            }
          });
        },
        { root: null, threshold: 0 }
      );
      observer.observe(page);
    },
  },

  mounted() {
    this.viewObserver();
  },

  created() {
    // Send request for banner image
    this.getBanner();

    // Send request for get total quantity of product`s id for
    // compare total length of products available on the page with total product`s id exist in the shop
    this.getTotalsId();

    this.getCategories();
  },

  // Watching for query params if use search, render found products;
  // for category`s, render products included in category and otherwise render all list of products
  watch: {
    "$route.query": {
      immediate: true,
      handler(val) {
        const { search, category } = val;
        if (search) {
          this.getProducts({ search });
          this.filter = true
        } else if (category) {
          this.getProducts({ id: category });
          this.filter = true;
        } else {
          this.getProducts({ page: this.page, limit: 6 });
          this.filter = false;
          // The best feature what I never used:)
          this.$nextTick(() => this.viewObserver())
        }
      },
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  border: 0;
}

.breadcrumb {
  display: flex;
  margin-top: 15px;
}
.breadcrumb li {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  z-index: 50;
}
.breadcrumb li a:hover {
  opacity: 45%;
}

.catalog__banner {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
}

/* .catalog__banner img {
  width: 100%;
  max-width: 1440px;
  object-fit: contain;
} */

.container {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 10px;
  margin-bottom: 50px;
}

.catalog__card {
  display: flex;
  gap: 20px 120px;
  row-gap: 20px;
  column-gap: 120px;
  margin-top: 50px;
  margin-bottom: 20px;
  flex: 1 1 70%;
}

.catalog__body {
  display: flex;
  flex-wrap: nowrap;
  column-gap: 5px;
}

.catalog__lists {
  flex: 1 0 25%;
}

.catalog__body > input,
.catalog__body > label {
  display: none;
}

.catalog__pages {
  /* border: 1px solid #75b051; */
  width: 100%;
  max-width: 280px;
  text-align: center;
  color: #75b051;
  margin-top: 50px;
  margin: 0 auto;
  /* margin-right: 25%;
  margin-left: auto; */
  border-radius: 5px;
}

.catalog__pages button {
  width: 100%;
  padding: 17px 0px;
  color: #7a7a7a;
  background: none;
  font-size: 18px;
  font-weight: 400;
}

@media screen and (max-width: 1440px) {
  .catalog_lists {
    flex: 1 0 20%;
  }
  .catalog__card {
    column-gap: 100px;
  }
}

@media screen and (max-width: 1024px) {
  .catalog__card {
    column-gap: 25px;
    row-gap: 25px;
  }
}

@media (max-width: 768px) {
  .catalog__banner {
    display: none;
  }
  .catalog__body > label {
    display: block;
    position: absolute;
    margin-top: 10px;
    z-index: 60;
    color: #75b051;
    font-size: 18px;
  }
  .catalog__card {
    justify-content: space-evenly;
    column-gap: 30px;
    row-gap: 30px;
  }

  .catalog__lists {
    position: absolute;
    max-height: 0;
    opacity: 0;
  }
  .catalog__body input:checked ~ .catalog__lists {
    display: flex;
    max-width: 100%;
    margin-top: 15px;
    max-height: 1000px;
    opacity: 1;
    transition: all 0.2s linear;
    /* For burger */
    z-index: 50;
    background-color: #ffffff;
    border-radius: 10px;
    /*  */
  }

  .catalog__lists .sliding {
    border-radius: 10px;
  }
}
</style>
