<template>
  <div class="card">
    <div class="mob-overlay" @click="productDescription"></div>
    <div class="card__image">
      <p class="stock-status" v-if="outOfStock">{{ product.stock_status }}</p>
      <img :src="product.image" alt="" :class="{ animation: addedToWish }" />
      <div class="overlay" @click="productDescription">
        <div class="overlay__text">
          <h3>ПОДРОБНЕЕ</h3>
        </div>
      </div>
      <div class="hover-block">

        <div class="dropdown">
          <button class="dropbtn" @click="addToCart">Добавить в корзину</button>
          <div class="dropdown-content">
            <a type="button" @click="addToWish(product.product_id)"
              >Добавить в избранное</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="card__text">
      <h3>{{ product.name }}</h3>
      <p>{{ product.meta_description }}</p>
      <div class="price-block">
        <span class="price" ref="price" :class="[{ old: product.special }]">{{
          product.price_formated
        }}</span>
        <span class="special" v-if="product.special">{{
          product.special_formated
        }}</span>
      </div>
    </div>
    <button class="product-ref" @click="addToCart">Добавить в корзину</button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import addToCart from '@/mixins/addToCart.js';

export default {
  props: {
    product: {
      type: Object,
      reqired: true,
    },
  },
  data() {
    return {
      addedToWish: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),

  },
  methods: {
    ...mapActions("wishlist", ["addToWishList"]),

    productDescription() {
      this.$router.push(
        "/products/" + this.product.seo_url + "_" + this.product.product_id
      );
    },
    addToWish(id) {
      if (this.isAuthenticated) {
        this.addedToWish = !this.addedToWish;
        this.addToWishList(id);
        // This timing function use here for animation, I not found another method how to realize it(
        setTimeout(() => (this.addedToWish = false), 500);
      } else {
        this.$router.replace("/auth");
      }
    },
    setOption(option) {
      console.log(option)
    }
  },
  mixins: [addToCart],
};
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30%;
  min-width: 160px;
  max-width: 260px;
  /* height: 100%;
  max-height: 290px; */
  /* margin-left: 10px; */
}
.card__image {
  position: relative;
}

.card__image > img {
  cursor: pointer;
  width: 100%;
  height: auto;
  /* Best approach to resize image, resized the width */
}
.card__image .hover-block {
  text-align: center;
  position: absolute;
  opacity: 0;
  width: 100%;
  bottom: 1px;
}
.card__image .overlay {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  background: #ffffff;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.card__image .overlay__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card__image .stock-status {
  max-width: 60%;
  text-align: center;
  border-radius: 8px;
  padding: 5px;
  font-size: 12px;
  color: #fff;
  background-color: #7a7a7a;
}

.card__image .overlay h4 {
  text-transform: uppercase;
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.card__image .hover-block .dropbtn {
  background-color: #75b051;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
  display: block;
  font-size: 13px;
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
}

.card__image .hover-block button.dropbtn:after {
  content: "...";
  font-size: 30px;
  line-height: 0;
  position: absolute;
  right: 5px;
}

h3 {
  padding: 15px 0 35px 0;
}

.dropdown {
  position: relative;
  display: block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  font-size: 13px;
  margin-top: 2px;
  padding: 12px 16px;
  border-radius: 5px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #75b051;
  color: #ffffff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.card__image:hover .hover-block {
  opacity: 1;
}
.card__image:hover .overlay {
  opacity: 0.7;
}
.card__text {
  max-width: 100%;
}
.card__text h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.card__text p {
  font-weight: 300;
  font-size: 13px;
  line-height: 27px;
  color: #7a7a7a;
}

.card__text .price,
.card__text .special {
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;
  color: #7a7a7a;
}
.card__text .special {
  margin-left: 40px;
  color: red;
}

.product-ref,
.mob-overlay {
  display: none;
}

.animation {
  animation: wish 0.5s linear;
}

@keyframes wish {
  0% {
    transform: translateX(0) scale(1);
  }
  25% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(0.4);
    /* opacity: 0; */
  }
  75% {
    transform: scale(0.1);
    /* opacity: 0; */
  }
  100% {
    transform: scale(0);
  }
}

@media (max-width: 1240px) {
  /* .card {
    width: 25%;
  } */
}

@media (max-width: 768px) {
  /* .card {
    min-width: 100px;
  } */
  .price-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card__text h3 {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    padding-bottom: 10px;
  }
  .card__text p {
    line-height: 20px;
    margin-bottom: 0;
  }

  .card__text .special {
    margin-left: 0;
  }

  .card__image .hover-block,
  .overlay {
    display: none;
  }

  .product-ref {
    display: block;
    justify-self: flex-end;
    width: 100%;
    background-color: #75b051;
    padding: 5px 10px;
    border-radius: 15px;
    color: #ffffff;
  }
  .mob-overlay {
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 80%;
    z-index: 30;
  }
}
</style>
