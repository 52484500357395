<template>
  <li class="radio-item">
    <input
      :id="elements.category_id"
      type="radio"
      :name="elements.parent_id"
      @change.stop="setFilter(elements.category_id)"
      :checked="isCheacked"
    />
    <label :for="elements.category_id"><img class="radio-img" :src="elements.image" width="15" height="15">{{ elements.name }}</label>
  </li>
</template>

<script>
// import { mapActions } from "vuex";

export default {
  props: {
    elements: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isCheacked() {
      if (this.$route.query.category && (this.$route.query.category == this.elements.category_id)) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    // ...mapActions("products", ["getProducts"]),

    // Refactor, instead applying to vuex, add id to query params, to do the code cleaner 
    setFilter(id) {
      if (id) {
        this.$router.push({
          name: 'Catalog',
          query: {
            category: id
          }
        })
      }
    },
  },
};
</script>

<style scoped>
li {
  padding: 5px 0;
}

input[type="radio"] + label {
  cursor: pointer;
}

/* Style for radio button from stackOverflow, remake all
radio button, but looks deferently on others computers*/

.radio-item {
  /* display: block; */
  /* min-width: 200px; */
  position: relative;
}

/* .radio-img {
  max-width: 100px;
  width: 100%;
  display: flex;
} */

.radio-item input[type="radio"] {
  display: none;
}

.radio-item label {
  display: flex;
  align-items: center;
  position: relative;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  top: 3px;
  margin: 0 10px 0 0;
  width: 15px;
  height: 15px;
  border-radius: 11px;
  border: 2px solid #75b051;
  background-color: transparent;
}

.radio-item input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 4px;
  left: 4px;
  transform: translate(-7%, -7%);
  /* Solo position: transform: translate(3.5px, -130%); */
  content: " ";
  display: block;
  background: #75b051;
}

@media (max-width: 768px) {
  .radio-item label:before,
  .radio-item input[type="radio"]:checked + label:after {
    display: none;
  }
  /* For burger */
  .radio-item label {
    font-family: 'Exo 2';
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;

  }
  /*  */
}

@media (max-width: 600px) {
  .radio-item {
    min-width: 100px;
}
}
</style>
